import React, {Component} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import ANSJIBanner from "../components/ANSJIBanner";
import Image from "../components/image"

class ANSJI extends Component {
    render() {
        return (
            <Layout
                headerElement={<ANSJIBanner siteTitle="African Nova Scotian Justice Institute" />}
                scrollStepInPx={50}
                delayInMs={2}
            >
            <SEO title="African Nova Scotian Justice Institute" />
            <section id="section2" style={{marginBottom: 80}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="text-justify" style={{fontSize: 18}}>The African Nova Scotian Justice Institute (ANSJI) will address issues of systemic racism in the justice system that are negatively impacting African Nova Scotians. The institute was established in 2021 and is the result of generations of work by justice advocates that have fought for the dignity and human rights of African Nova Scotians throughout history.<br /><br />
                                The issue of street checks, the continued treatment and overrepresentation of African Nova Scotians in the criminal justice system, and moments in history such as the Royal Commission on the Donald Marshall Jr. Prosecution report, have led the ANSJI to take a proactive approach to developing an African Nova Scotian-led justice strategy.
                                <br />
                                <br />
                            </p>
                            <ul className="ansji-list">
                                <li className="ansji-ordered-list">The ANSJI will create programs and services that adequately address institutional racism faced by African Nova Scotians and their families when dealing with human rights complaints, the criminal justice system, all forms of law enforcement, police and incarceration.</li>
                                <li className="ansji-ordered-list">The ANSJI is the originator of Impact of Race and Culture Assessments (IRCA). The institute is working to create an infrastructure to provide ongoing training and support for counsellors, mental health and social work professionals carrying out IRCAs in Nova Scotia and across Canada.</li>
                                <li className="ansji-ordered-list">The ANSJI will be a place African Nova Scotians can turn to when seeking legal advice, guidance and advocacy that understands African Nova Scotians as a <b>distinct people*.</b></li>
                                <li className="ansji-ordered-list">The ANSJI will be a regional and national leader in legal research, advocacy and education on justice issues affecting African Nova Scotian communities.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="mt-5">Employment Opportunities</h2>
                            <p className="text-justify" style={{fontSize: 18}}>The African Nova Scotian Justice Institute is please to offer the following 5 employment opportunities:<br /><br />
                                <ul className="ansji-list">
                                    <li className="ansji-ordered-list"><b>&nbsp;Legal Support/ Researcher:</b><br />The Legal Support/Researcher will be responsible for conducting the research required for the ANSJI to advocate for changes to laws, policies, and practices that seek to improve the quality of life for African Nova Scotians who seek to navigate legal processes to defend and protect their human rights. The legal support/research person will also assist in the preparation of public legal information documents as well as compiling and organizing sample legal documents. The legal support/research person will also be available to provide support to individuals as they navigate legal processes. This may include attending meetings, taking notes, providing referrals, and assisting in the preparation of documents for administrative processes. This position will report directly  to the Staff Lawyer and Senior Legal Counsel. <br /> <a href="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/Job+posting-+Legal+Support_+Researcher+.docx-3.pdf" target="_blank"> Learn More</a></li>
                                    <li className="ansji-ordered-list"><b>&nbsp;Staff Lawyer:</b><br />Reporting to the Senior Legal Counsel, the Staff Lawyer will provide free independent legal information and advice to ANSJIs target population. The lawyer will assist persons in navigating informal and formal legal processes in Nova Scotia, with a focus on human rights, employment discrimination, and policing issues. The lawyer will assist in providing access to justice as persons navigate legal and quasi-legal bodies such as the Office of the Police Complaints Commissioner, local police boards, the Commission for Public Complaints against the RCMP and applications to the Human Rights Commission of Nova Scotia or the Canadian Human Rights Commission. <br />The staff lawyer will also oversee initiatives related to research, public legal education and support. ANSJI seeks to partner with relevant organizations and institutions to create self-help guides and pre-recorded advice sessions, co-host workshops, lectures, and engagement opportunities, and youth led programming and peer training. <br />*** Please note that while this position requires the individual to be a lawyer in good standing with the Nova Scotia Barristers Society, they will not be engaging in any litigation as part of the duties of this role. <br /> <a href="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/Job+posting-+Staff+Lawyer+.docx-2.pdf" target="_blank"> Learn More</a></li>
                                    <li className="ansji-ordered-list"><b>&nbsp;Clinician:</b><br />The Clinician will carry out Impact of Race and Cultural Assessments (IRCAs) while working with the Senior Clinician to strategically advocate for increased access to mental health services for African Nova Scotians navigating the criminal justice system. Training will be provided to the successful individual to develop the knowledge and skills required to complete IRCAs. <br /> <a href="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/Job+posting-+Clinician+.docx-2.pdf" target="_blank"> Learn More</a></li>
                                    <li className="ansji-ordered-list"><b>&nbsp;Administrative Assistant:</b><br />The Administrative Assistant will provide direct support to the ANSJI Staff and support the administrative functions of the ANSJI. The Administrative Assistant will report directly to the Operations Manager. <br /> <a href="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/Job+posting-+Admin+Assistant+.docx-2.pdf" target="_blank"> Learn More</a></li>
                                    <li className="ansji-ordered-list"><b>&nbsp;National IRCA Training Program- Project Coordinator:</b><br />The Project Coordinator will assist the Training Lead in the development and Implementation of the National Impact of Race and Culture (IRCA) Training Program. The Project coordinator will be responsible for making connections with community organizations serving individuals of African descent as well as collecting and tracking Data on IRCAs both Nationally as well as within Nova Scotia. The Project coordinator will report to the Senior Clinician- Forensic Assessment and Treatment Unit of ANSJI. <br />Please see the attached descriptions for more information about the postings. If you are interested in any of these positions please submit your expression of interest to the ANSJI Operations Manager, Sarah Upshaw, at sarah.upshaw@ansji.ca, no later than Midnight on October 16th, 2023.<br /> <a href="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/Job+posting-+NITP+Project+Coordinator+.docx-3.pdf" target="_blank"> Learn More</a></li>
                                </ul>
                            </p>
                            <p className="text-justify" style={{fontSize: 18}}>
                                <br />
                                Please see the attached descriptions for more information about the postings. If you are interested in any of these positions please submit your expression of interest to the ANSJI Operations Manager, Sarah Upshaw, at sarah.upshaw@ansji.ca, no later than Midnight on October 16th, 2023.
                                <br />
                                Phone number: (902) 492-5619<br />
                                info@ansji.ca<br />
                                ircarequest@ansji.ca<br />
                            </p>
                        </div>
                    </div>                    
                </div>
            </section>
            </Layout>
        );
    }
}

export default ANSJI